import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

const theme = createTheme({
    typography: {
        gtitle: {
            fontSize: '42pt',
            fontWeight: 600,
            color: '#00652b',
            lineHeight: '42pt',
            '@media (max-width: 992px)': {
                fontSize: '32pt',
            },
        },
        smtext: {
            fontSize: '12pt',
            color: 'black'
        },
        ctext: {
            fontSize: '18pt',
            textAlign: 'center',
            color: 'black'
        },
        ltext: {
            fontSize: '16pt',
            textAlign: 'left',
            color: 'black'
        }
    },

});

export default function Paragraph({ text, variant }) {

    const { t } = useTranslation();

    return (
        <Typography variant={variant} theme={theme} paragraph={true}>
                {t(text)}
        </Typography>
    )
}