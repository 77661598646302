import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import Fab from '@mui/material/Fab';
import i18n from "i18next";
import SearchAutocomplete from './SearchAutocomplete';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

import { FacebookShareButton, TwitterShareButton } from "react-share";
/*import { FacebookIcon, TwitterIcon } from "react-share";*/

const useStyles = makeStyles({
  header: {
    backgroundColor: "#00652b",
    color: "white",
    boxShadow: "0px 0px 0px 0px",
  }

});

export default function SearchAppBar() {

  const classes = useStyles();
  const location = useLocation();

  const [lang, setLang] = useState(1);

  useEffect (() => {

    if (i18n.language === "de") {
	setLang(1);
	i18n.changeLanguage("de");
    }
    
    if (i18n.language === "it") {
	setLang(2);
	i18n.changeLanguage("it");
    } else {
	setLang(1);
	i18n.changeLanguage("de");
    }
    
  }, [])


  const changeLanguageHandler = (() => {

    if (i18n.language === "it") {
      setLang(1);
      i18n.changeLanguage("de");
    }
    else if (i18n.language === "de") {
      setLang(2);
      i18n.changeLanguage("it");
    } else {
      setLang(1);
      i18n.changeLanguage("de");
    }

  })

  const share = (() => {
    console.log("https://naturimgarten.laimburg.it" + location.pathname);
  })

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: '#00652b'
      }}>

      <AppBar id='navigation' position="static" className={classes.header}
        sx={{
          width: '100%',
        }}
      >

        <Toolbar
          id="toolbar"
          className="toolbar"
          sx={{
            backgroundColor: "#00652b", float: 'right',
            minHeight: '64px',
            paddingLeft: '15px',
          }}
        >
          <Grid container
            direction="row"
            spacing={0}
            justifyContent="flex-end"
            alignItems="center"
            >

            <Grid id="sf" item sx={{
                flexGrow: '1',
                alignContent: 'end',
                maxWidth: '300px'
                }}>
                  <SearchAutocomplete />
            </Grid>

            {/* Image Buttons */}
            <Grid item>

              <Fab size="small" aria-label="e-mail" href='#formular' sx={{
                color: 'white',
                margin: '5px 5px 5px 0px',
                backgroundColor: 'transparent',
                border: 1,
                verticalAlign: 'top',
                boxShadow: 0
              }}
                className='icons'>
                <MailOutlineOutlinedIcon />
              </Fab>

            </Grid>
            <Grid item>

              <Fab size="small" aria-label="language" sx={{
                color: 'white',
                margin: '5px 5px 5px 0px',
                backgroundColor: 'transparent',
                border: 1,
                verticalAlign: 'top',
                boxShadow: 0
              }}
                className='icons'
                onClick={changeLanguageHandler}
              >

                 {lang === 1 && <> <Typography>IT</Typography> </> }
                 {lang === 2 && <> <Typography>DE</Typography> </> }

              </Fab>

            </Grid>
            <Grid item sx={{margin: '5px'}}>

              <FacebookShareButton
                sx={{
                  verticalAlign: 'center'
                }}
                url={"https://naturimgarten.laimburg.it"}
              >
                <FacebookIcon onClick={share}
                  sx={{
                    verticalAlign: 'center'
                  }}
                />
              </FacebookShareButton>

              </Grid>
              <Grid item sx={{margin: '5px'}}>

              <TwitterShareButton
                sx={{
                  verticalAlign: 'center'
                }}
                url={"https:/naturimgarten.laimburg.it" + location.pathname}
                quote={"Natur im Garten"}
                hashtag={"#"}
              >
                <TwitterIcon onClick={share}
                  sx={{
                    verticalAlign: 'center'
                  }}
                />
              </TwitterShareButton>

            </Grid>

          </Grid>


      </Toolbar>
    </AppBar>
    </Box >
  );
}


