import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Title } from '../../../Title';

const theme = createTheme({
  typography: {
    gtitle: {
      fontSize: '42pt',
      fontWeight: 600,
      color: '#00652b',
      lineHeight: '42pt',
      '@media (max-width: 992px)': {
        fontSize: '32pt',
      },
    },
    text: {
      fontSize: '18pt',
      color: 'black',
      '@media (max-width: 992px)': {
        fontSize: '16pt',
      },
    }
  },

});

var cardStyle = {
  display: 'block',
  /*height: '45vw'*/
}


export default function BestPractCards({ image, title, body }) {
  const { t } = useTranslation();

  let im = "Best/".concat(image)
  return (
    
    <Box >
     <Card style={cardStyle} sx={{ border: '1px solid #00652b' }} className='cardscarousel'>
      <CardActionArea>
        
        {image === null ? '' : <CardMedia
          component="img"
          height="300"
          image={im}
          
        />}
 
        <CardContent
         >
          <Title title={title} variant="title"></Title>

          <Box className='pad' />
            
            {body.map((item, index) => {
              return (<Typography key={index} paragraph={true} variant='h6' theme={theme} >
                {t(item)}
              </Typography>)
            })}
          
        </CardContent>
      </CardActionArea>
    </Card>
    
    </Box>
    
  );
}