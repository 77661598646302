import * as React from 'react';
import { useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import Paragraph from '../../Paragraph';
import Image from '../../Image';
import HyperLink from '../../HyperLink';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import List from '../../List';
import Underline from '../../Underline';
import { Title } from '../../Title';

import { useTranslation } from "react-i18next";
import { useMatomo } from '@datapunt/matomo-tracker-react';



const theme = createTheme({
    typography: {
        gtitle: {
            fontSize: '32pt',
            fontWeight: 600,
            color: '#00652b',
            lineHeight: '32pt',
            '@media (max-width: 992px)': {
                fontSize: '32pt',
            },
        },
        smtext: {
            fontSize: '12pt',
            color: 'black',
            fontWeight: 'bold',
            fontStyle: 'italic'
        },
        ctext: {
            fontSize: '16pt',
            color: 'black',

        }
    },

});

const GButton = styled(Button)({
    borderRadius: 0,
    color: 'white',
    backgroundColor: '#00652b',
    fontWeight: 'bold',
    fontSize: '16pt',
    textTransform: 'uppercase',
    minWidth: '210px',
    minHeight: '60px',
    '&:hover': {
        backgroundColor: '#00652b',
    },



});

export default function CardsGartentipp({ item }) {

    const { t } = useTranslation();

    const { trackPageView } = useMatomo();

     const goBack = () => {
        window.scrollTo(0, 0);
    }

    useEffect (() => {
        window.scrollTo(0, 0);
    }, [item])


    // Track page view
    React.useEffect(() => {

	if (item !== undefined)
        trackPageView({
          documentTitle: 'Gartentipps', // optional
          href: 'https://naturimgarten.laimburg.it/gardentips/'+item.title, // optional
          /*customDimensions: [
            {
              id: 1,
              value: 'loggedIn',
            },
          ],*/ // optional
        })
      }, [trackPageView, item])

    //let im = "Gardentips/".concat(image);

    return (

        <Box className='maintextbox' style={{paddingTop: '100px'}}>

            {/*  {item.id} */}
	    {item !== undefined &&
	    <> 
            <Title title={item.title} variant="title"></Title>

            <Box className='pad' />

            {/* Green body*/}
            <Box sx={{ textAlign: 'justify' }}>
                <Typography variant="ctext" theme={theme} >
                    {item.gbody}
                </Typography>

                <Box className='pad' />

                {/* Black body*/}
                <Typography variant="ctext" theme={theme}>
                    {item.body}
                </Typography>
            </Box>

            <Box className='pad' />
            {/* Array of document */}
            {item.document.map((row, index) => {

                let im = "Gardentips/".concat(row.image);

                if (row.image !== undefined)
                    return (<Image key={index} image={im} figure={row.figure} />);

                if (row.paragraph !== undefined)
                    return (<Paragraph key={index} text={row.paragraph} paragraph={true} variant="ltext"/>);

                if (row.link !== undefined)
                    return (<HyperLink key={index} link={row.link} placeholder={row.placeholder} />);

                if (row.list !== undefined)
                    return (<List key={index} list={row.list} />);

                if (row.title !== undefined)
                    return (<Typography sx={{paddingTop: "40px;"}} variant="h6" align="left"><strong>{row.title}</strong></Typography>);

                if (row.underline !== undefined)
                    return (<Underline key={index} underline={row.underline} />);

                return "";
            }
            )}

            <Box className='pad' />
            
            <Box sx={{ textAlign: 'justify' }}>
                <Typography variant="smtext" theme={theme}>
                    {item.author}
                </Typography>
            </Box>

            <Box sx={{ textAlign: 'right', width: '100%', marginTop: '50px' }}>
                <Link to="/gardentips" onClick={goBack} >
                    <GButton variant="contained" size="large" >
                        {t('back')}
                    </GButton>
                </Link>
            </Box>
	    </>
	    }

        </Box>


    );
}