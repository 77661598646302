import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';


export function Sidebar({ callback }) {

  const { t } = useTranslation();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (

    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        backgroundColor: '#00652b',
        opacity: '0.9'
      }}

      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}

    >

      <Typography className='popover'  sx={{ borderTop: 3 }}>
        <Link to="/" className='link'>
            {/*    href="/" */}
            {t('NIG')}
        </Link>
      </Typography>


      <Divider />


      <Typography className='popover'  sx={{ borderTop: 3 }}>
        <Link to="/services" className='link' >
          {t('services')}
        </Link>
      </Typography>

      <Typography className='popover'>
        <Link to="/public" className='link'>
          {t('municipalities')}
        </Link>
      </Typography>

      <Typography className='popover'
        sx={{ paddingLeft: '50px' }}>
          <HashLink to="/public#consultation"  className='link'>
          {t('consultation')}
          </HashLink>
       
      </Typography>

      <Typography className='popover'
        sx={{ paddingLeft: '50px' }}>
        <HashLink to="/public#bestpractice" className='link'>
          {t('best_practice')}
        </HashLink>
      </Typography>

      <Typography className='popover'>
        <Link to="/private" className='link'>
          {t('private')}
        </Link>
      </Typography>

      <Typography className='popover'
        sx={{ paddingLeft: '50px' }}>
        <HashLink to="/private#criterias" className='link'>
          {t('criterias')}
        </HashLink>
      </Typography>

      <Typography className='popover'
        sx={{ paddingLeft: '50px' }}>
        <HashLink to="/private#badge" className='link'>
          {t('badge_award')}
        </HashLink>
      </Typography>

      <Typography className='popover'
        sx={{ paddingLeft: '50px' }}>
        <HashLink to="/private#elements" className='link'>
          {t('nig_elements_title')}
        </HashLink>
      </Typography>

      <Typography className='popover'
        sx={{ paddingLeft: '50px' }}>
        <HashLink to="/private#care" className='link'>
          {t('nig_pflege_title')}
        </HashLink>
      </Typography>

      <Typography className='popover'>
        <Link to="/tours" className='link'>
          {t('tours')}
        </Link>
      </Typography>

      <Divider />

      <Typography className='popover' sx={{ borderTop: 3 }}
      >
        <Link to="/news" className='link'>
          {t('news')}
        </Link>
      </Typography>


      <Typography className='popover' sx={{ borderTop: 3 }}
      >
        <Link to="/events" className='link'>
          {t('events')}
        </Link>
      </Typography>
      
      <Typography className='popover'>
        <Link to="/gardentips" className='link'>
          {t('gardentips')}
        </Link>
      </Typography>

      
      <Divider />

      <Typography className='popover' sx={{ borderTop: 3 }}>
      <Link to="/downloads" className='link'>
        {t('downloads')}
      </Link>
      </Typography>


    </Box>
  );

  return (
    <>
      {['top'].map((anchor) => (

        <React.Fragment key={anchor}>

          {/* <Button onClick={toggleDrawer(anchor, true)} >{anchor} </Button> */}

          <MenuIcon fontSize='large' onClick={toggleDrawer(anchor, true)} />

          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>

        </React.Fragment>

      ))}
    </>
  );

}

export default Sidebar;