import * as React from 'react';
import { useLocation } from 'react-router-dom';
import mainImage from '../../Links/carthusian-pink-g00eec1f9b_1920.jpg';
import Box from '@mui/material/Box';
import TextBox from './Textbox';
import Beratungen from './Beratungen';
import Werwirsind from './Werwirsind';
import Photogallery from './Photogallery';
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default  function Startseite () {

  let location = useLocation();
  const { trackPageView } = useMatomo()


  React.useEffect(() => {
    let lo = location.hash.substring(1);
    if (lo)
    {
      let el = document.getElementById(lo);
      if (el != null)
        el.scrollIntoView()
    }
  }, [location]);

  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Startseite', // optional
      href: 'https://naturimgarten.laimburg.it', // optional
      customDimensions: [
        {
          id: 1,
          value: 'loggedIn',
        },
      ], // optional
    })
  }, [trackPageView])

  return (

    <Box>

      <Box className='mainimagebox' >
        <img className='mainimage'
          src={mainImage}
          alt="main"
          width='50%'
        />
      </Box>

      <TextBox />
      
      <Box className='carousel' >
        <Photogallery />
      </Box>

      {/*
        <Kernkriterien />
      */}

      <Beratungen />

      <Werwirsind />

      {/*
      <Wastunwir />

      <UnserAng />
   

      <Pictures />
       */}

  

    </Box>
  );
}
