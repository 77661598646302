import * as React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function ColorButton ({ text, link, color }) {

  const { t } = useTranslation();

  const click = () => {
    window.scrollTo(0, 0)
  }

  return (
    <>

      <Box className='cardsbutton'
        sx={{
          display: "inline-block",
        }} >
        <Link to={link} onClick={click}>

          <Box id="Test"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '16pt',
              fontWeight: '600',
              color: "white",
              backgroundColor: "#00652b",
              cursor: 'pointer',
              textTransform: 'uppercase',
              minWidth: "210px",
              minHeight: "60px"
            }}
          >
            <span className="button-text">{t(text)}</span>
          </Box>
        </Link>
      </Box>


    </>
  )
}

export default ColorButton