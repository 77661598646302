import * as React from 'react';
import mainImage from '../../../Links/Balkonversuch-gesamt.JPG';
import Box from '@mui/material/Box';
import WelcheFührug from './WelcheFührug'
import Photogallery from './Photogallery';
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function Führungen() {

  const { trackPageView } = useMatomo()

  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Führungen', // optional
      href: 'https://naturimgarten.laimburg.it', // optional
      customDimensions: [
        {
          id: 1,
          value: 'loggedIn',
        },
      ], // optional
    })
  }, [trackPageView])

  return (

    
    <Box>

      <Box className='mainimagebox' >
        <img className='mainimage'
          src={mainImage}
          alt="placeholder"
          width='100%'
        />
      </Box>

      <WelcheFührug />

      <Photogallery/>
      
      {/* 
        <Box>
          <PicturesFühr />
        </Box>
      */}

    </Box>
  );
}