import Navigationbar from "./components/Navigationbar";
import Menubar from "./components/Menubar";
import Startseite from './components/routes/Home/Startseite';
import { BrowserRouter } from "react-router-dom"
import { Route, Routes } from "react-router-dom"
import Footer from "./components/Footer";
import Private from './components/routes/Services/private/Private';
import Gemeinden from './components/routes/Services/Gemeinden';
import Führungen from './components/routes/Services/Tour/Führungen';
import Gartentipps from './components/routes/Tips/Gartentipps';
import Events from './components/routes/Events/Events';
import News from './components/routes/News/News';
import Downloads from './components/routes/Downloads/Downloads';
import Dienstleistungen from './components/routes/Services/Dienstleistungen';

import "./i18n";

import './App.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Avenir',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});

function App() {

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <BrowserRouter>

      <Navigationbar />
      <Menubar />
      
        <Routes>
          <Route exact path="/" element={<Startseite />} />


          <Route exact path="/services" element={<Dienstleistungen />} />

          <Route exact path="/public" element={<Gemeinden />} />

          <Route exact path="/private" element={<Private />} />

          <Route exact path="/tours" element={<Führungen />} />

          <Route exact path="/gardentips" element={<Gartentipps />} />

          <Route exact path="/news" element={<News />} />

          <Route exact path="/events" element={<Events />} />

          <Route exact path="/downloads" element={<Downloads />} />
         
        </Routes>
        
        
      </BrowserRouter>

      
      <Footer />
    </div>
    </ThemeProvider>
  );
  
    
   

}

export default App;
