import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AButton = styled(Button)({
  borderRadius: 0, 
  fontSize: '16pt',
  color: 'white',
  textTransform: 'none',
  paddingBottom: '0px',
  paddingLeft: '15px',
  '&:hover': {
    boxShadow: '0px 3px white',
  },
  '&:active': {
    boxShadow: 'none',
    borderRadius: 0, 
  },
  '&:focus': {
  borderRadius: 0, 
   boxShadow: '0px 3px white',
  },
  
});



export function MenuButton4({callback}) {

  const { t } = useTranslation();
  
  return (
    
    <Link to="/downloads">
      <AButton size="large" onClick={callback}>
         {t('downloads')}
      </AButton>
   </Link>
  );
}