import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import CardEvent from './CardEvent';

import { useState, useEffect } from 'react';


export default function EventsPrime ({ list }) {

  const [cards, setCards] = useState(list);

  const [extend, setExtend] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    let newarray = []

    list.map((item, index) => {

      if (extend)
        newarray.push(item);
      else
        if (index < 4) // 2
          newarray.push(item)
      return 0;
    })

    setCards(newarray);
  }, [extend, list])


  return (

    <>
      <Grid container spacing={0} className='cardbox' >

        {cards.map((row, index) => {
          return row.visible ? <CardEvent key={index} idCard={index+1} image={row.image} title={row.title} date={row.date}
            time={row.time} place={row.place} body={row.body} /> : ''
        })}

      </Grid>

      <Box id='t'></Box>


      {list.length > 4 ? // 1
        <>
          <Typography id="btn" >

            {t('load_events')}

          </Typography>
          <br />
          <IconButton onClick={() => { readmore(); setExtend(!extend); }}>
            <SlArrowDown id='btndown' className='arrows' />
            <SlArrowUp id='btnup' className='arrows' />
          </IconButton>
        </>
        : ""
      }

    </>

  );
}

function readmore() {
  var t = document.getElementById('t');
  var btn = document.getElementById('btn');
  var btndown = document.getElementById('btndown');
  var btnup = document.getElementById('btnup');


  if (t.style.display === 'none') {
    btn.style.display = 'InLine';
    t.style.display = 'InLine';
    btndown.style.display = 'InLine';
    btnup.style.display = 'none';
  }
  else {
    btn.style.display = 'none';
    t.style.display = 'none';
    btndown.style.display = 'none';
    btnup.style.display = 'Inline';

  }
}
