import * as React from 'react';
import { useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paragraph from '../../Paragraph';
import Image from '../../Image';
import HyperLink from '../../HyperLink';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Bold from '../../Bold';
import List from '../../List';
import { Title } from '../../Title';
import { useMatomo } from '@datapunt/matomo-tracker-react'

const theme = createTheme({
    typography: {
        gtitle: {
            fontSize: '32pt',
            fontWeight: 600,
            color: '#00652b',
            lineHeight: '32pt',
            '@media (max-width: 992px)': {
                fontSize: '32pt',
            },
        },
        smtext: {
            fontSize: '12pt',
            color: 'black'
        },
        ctext: {
            fontSize: '16pt',
            color: 'black',
            
        }
    },

});

const GButton = styled(Button)({
    borderRadius: 0, 
    color: 'white',
    backgroundColor: '#00652b',
    fontWeight: 'bold',
    fontSize: '16pt',
    textTransform: 'uppercase',
    minWidth: '210px',
    minHeight: '60px',
    '&:hover': {
        backgroundColor: '#00652b',
      },
  });


export default function NewsItem ({ item }) {

    const { t } = useTranslation();

    const { trackPageView } = useMatomo();

    /*const location = useLocation();*/

    const goBack = () => {
        window.scrollTo(0, 0)
    }

    useEffect (() => {
        window.scrollTo(0, 0)
    }, [])

    // Track page view
    React.useEffect(() => {
	if (item !== undefined)
        trackPageView({
        documentTitle: item.title, // optional
        href: 'https://naturimgarten.laimburg.it/events/'+item.id, // optional
        customDimensions: [
            {
            id: 1,
            value: 'loggedIn',
            },
        ], // optional
        })
    }, [trackPageView, item])
   
    return (


        <div className='maintextbox' >
	    {item !== undefined &&
	    <>
            <Title title= {item.title} variant="title"></Title>

            <Box className='pad' />

            <Box sx={{textAlign: 'justify'}}>

            {/* Green body*/}
            <Typography variant="ctext" theme={theme} >
                {item.date}
            </Typography>

            <Box className='pad' />
            <Typography variant="ctext" theme={theme} >
                {item.time}
            </Typography>

            <Box className='pad' />
            <Typography variant="ctext" theme={theme} >
                {item.place}
            </Typography>

            <Box className='pad' />

            {/* Black body*/}
            <Typography variant="ctext" theme={theme}>
                {item.body}
            </Typography>
            <Box className='pad' />
            </Box>
            {/* Array of document */}

            
            {item.document.map((row, index) => {

                if (row.image !== undefined)
                    return (<Image key={index} image={row.image} figure={row.figure} />);

                if (row.paragraph !== undefined)
                    return (<Paragraph key={index} text={row.paragraph} variant="ltext"/>);

                if (row.link !== undefined)
                    return (<HyperLink key={index}  link={row.link} placeholder={row.placeholder} />);
                
                if (row.bold !== undefined)
                    return (<Bold key={index}  bold={row.bold} />);

                if (row.list !== undefined)
                    return (<List key={index}  list={row.list} />);
                
                return "";
            }
            )}
            
             <Box sx={{textAlign: 'right', width: '100%', marginTop: '50px'}}>
            <Link to="/news" onClick={goBack} >
            <GButton variant="contained" size="large" >
                    {t('back')}
                    </GButton>
            </Link>
            </Box>

            </>
	    }

               
                   
              

        </div>


    );
}