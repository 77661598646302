import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import './Menubar.css';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import { MenuButton2 } from './MenuButton2';
import { MenuButton3 } from './MenuButton3';
import { MenuButton4 } from './MenuButton4';
import { useState } from "react";

import { Sidebar } from "./Sidebar";
import { useTranslation } from "react-i18next";

import logode from './NiG_Logo_VERORTUNG_Südtirol.png';
import logoit from './NiG_Logo_VERORTUNG_Südtirol Alto Adige.png';

export default function Menubar() {

  const { i18n, t } = useTranslation();

  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [ logo, setLogo] = useState();

  const toggleCallback = () => {
    setIsNavExpanded (!isNavExpanded);
  }

  useEffect (() => {

    switch (i18n.language)
    {
      case ("de"):
        setLogo(logode);
        break;
      case ("it"):
        setLogo(logoit);
        break;
      default:
        setLogo(logode);
    }
    console.log ("Hallo");
  },[i18n.language]);

  return (

    <Grid container sx={{ boxShadow: 1}} className='menubox'>
        
        <Grid container >
          <Grid item className="logo">
            
            <Link to="/">
              {i18n.language==="de" && <img className="logo" src={logode} alt="logode" id='logode'/> }
              {i18n.language!=="de" && <img className="logo" src={logoit} alt="logoit" id='logoit'/> }
            </Link>
          </Grid>

          <Grid item sx={{flexGrow: '1'}}></Grid>

          <Grid item>
            <Box className='menu'>
              <ul>
                {/* 
                  <li><MenuButton callback={toggleCallback} /></li>
                */}
                <li><MenuButton2 callback={toggleCallback} /></li>
                <li><MenuButton3 callback={toggleCallback} /></li>
                <li><MenuButton4 callback={toggleCallback} /></li>
              </ul>

              <div className="hamburger">
                <Sidebar callback={toggleCallback} />
              </div>

            </Box>
          </Grid>
        </Grid>

    </Grid>


  );
}




