import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const theme = createTheme({
    typography: {
        gtitle: {
            fontSize: '42pt',
            fontWeight: 600,
            color: '#00652b',
            lineHeight: '42pt',
            '@media (max-width: 992px)': {
                fontSize: '32pt',
            },
        },
        smtext: {
            fontSize: '10pt',
            color: 'black'
        },
        ctext: {
            fontSize: '16pt',
            color: 'black',
            
        }
    },
    a: {
        fontSize: '16pt',
    }

});

export default function Hyperlink ({ link, placeholder }) {

    return (
        <Box sx={{textAlign: 'justify'}}>
            
            <a href={link} className='hyperlink' >
                <strong><span theme={theme.ctext}> {placeholder}</span></strong>
            </a>
            
        </Box>
    )
}