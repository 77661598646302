import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Title } from '../../../Title';
import { TextBlack } from '../../../TextBlack';
import { HashLink } from "react-router-hash-link";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


const WButton = styled(Button)({
  borderRadius: 0,
  color: 'white',
  backgroundColor: '#00652b',
  fontWeight: 'bold',
  fontSize: '18pt',
  textTransform: 'uppercase',
  border: 'green solid 1px',
  minWidth: '200px',
  Height: '60px',
  '&:hover': {
    border: 'black solid 1px',
    color: 'black',
    backgroundColor: 'white',
  },
});

const click = () => {
  window.scrollTo(0, 0)
}

const theme = createTheme({
  typography: {
    gtitle: {
      fontSize: '42pt',
      fontWeight: 600,
      color: '#00652b',
      lineHeight: '42pt',
      '@media (max-width: 992px)': {
        fontSize: '32pt',
      },
    },
    text: {
      fontSize: '18pt',
      color: 'black',
      '@media (max-width: 992px)': {
        fontSize: '16pt',
      },
    }
  },

});


export default function WelcheFührug() {

  const { t } = useTranslation();

  return (
    
    <Box className='maintextbox' sx={{
      marginTop: 2,
    }}>

      <Title title="tours_in_laimburg" variant="title"></Title>

      {[1, 2, 3, 4, 5, 6].map((item, index) => {

        let title = "tour_".concat(item, "_title");
        let body = "tour_".concat(item, "_body");
        let time = "tour_".concat(item, "_time");
        let i = "b".concat(index);
        let j = "c".concat(index);

        return (

          <div key={index}>
            <Typography key={index} theme={theme} variant="h5" className='title' paragraph={true}
              sx={{
                paddingTop: '20px',
                paddingBottom: '5px',
                fontWeight: '600',
                color: '#00652b'
              }}
            >
              {t(title)}
            </Typography>

            <TextBlack key={i} text={body}></TextBlack>
            <TextBlack key={j} text={time}></TextBlack>
          </div>
        )
      })
      }

      {/*

    

       */}

      <HashLink to="https://laris.scientificnet.org/extern/tour/start" target="_blank">
        <WButton variant="outlined" onClick={click}>{t('book_right_now')}</WButton>
      </HashLink>


      {/*
      <Title title="tour_1_title" variant="h4"></Title>
      <TextBlack text="tour_1_body"></TextBlack>
      <TextBlack text="tour_1_time"></TextBlack>

       
      <TextBlack text="tours_in_laimburg_1" />

      <TextGreen text="tours_in_laimburg_2" />

     
      <a href={t("link_to_tours")}>
        <TextBlack text="link_to_tours" />
      </a>
    
      <TextBlack text="tours_in_laimburg_3" />

      <Typography variant='text' theme={theme} paragraph={true}>

        Tel. +39 0471 969 623 / <br/>
        E-Mail tours@laimburg.it

      </Typography>
      */}

    </Box>
  );
}