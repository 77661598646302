import * as React from 'react';
import Grid from '@mui/material/Grid';

import CardD from './CardD';


/*
import { createTheme } from '@mui/material/styles';
const theme = createTheme({
  typography: {
    gtitle: {
      fontSize: '42pt',
      fontWeight: 600,
      color: '#00652b',
      lineHeight: '42pt',
      '@media (max-width: 992px)': {
        fontSize: '32pt',
      },
    },
    ctextgb: {
      fontSize: '16pt',
      fontWeight: 600,
      color: '#00652b'
    },
    ctext: {
      fontSize: '16pt',
      color: 'black'
    }
  },

});*/


export default function CardsDownloads({ list }) {



  return (

    <div >
      <div >

        <Grid container spacing={0} className='cardbox' >

          {list.map((row, index) => {
            return row.visible ? <CardD key={index} idCard={row.id} image={row.image} title={row.title} body={row.body} /> : ''
          })}

        </Grid>

      </div>

      {/* 
      <Box id='t'></Box>
    

      <Typography id='btn' >

        {t('mehr Gartentipps laden')}

      </Typography>
      <br />
      <IconButton onClick={() => {readmore(); setExtend(!extend);  }}>
        <SlArrowDown id='btndown' className='arrows' />
        <SlArrowUp id='btnup' className='arrows' />
      </IconButton>
*/}
    </div>

  );
}
