import React from 'react';
import { Box } from '@mui/material'

import Image1 from '../../../../Images/Tour/Gartenbau2020_ICF3948.jpg';
import Image2 from '../../../../Images/Tour/Belgische Gartenbauschüler Mai 2023.jpg';
import Image3 from '../../../../Images/Tour/2023 Mai (19).jpg'
import Image4 from '../../../../Images/Tour/Versuchsdach Laimburg.jpg'
import Image5 from '../../../../Images/Tour/Rottensteiner Fotograf29.jpg';
import Image6 from '../../../../Images/Tour/IMG_1926.JPG';
import Image7 from '../../../../Images/Tour/Stadlhof.JPG';
import Image8 from '../../../../Images/Tour/2023 Mai (10).jpg'

import Carousel, { consts } from 'react-elastic-carousel';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

var items = [
    {
        image: Image1,
    },
    {
        image: Image2,
    },
    {
        image: Image3,
    },
    {
        image: Image4,
    },
    {
        image: Image5,
    },
    {
        image: Image6,
    },
    {
        image: Image7,
    },
    {
        image: Image8,
    }
]




export default function Photogallery() {

    return (
        <Box sx={{
        }}>
            <div className='carousel-g'>
                <Carousel
                    itemsToShow={3}
                    itemsToScroll={1}
                    itemPadding={[0, 5, 0, 5]}
                    enableSwipe={true}
                    disableArrowsOnEnd={false}
                    pagination={false}
                    renderArrow={({ type, onClick }) => {
                        const pointer = type === consts.PREV ? <SlArrowLeft className='arrows' /> :
                            <SlArrowRight className='arrows' />
                        return <button onClick={onClick} className='arrows'>{pointer}</button>
                    }}
                >
                    {
                        items.map((item, index) => <Item key={index} item={item} />)
                    }
                </Carousel>
            </div>

            <div className='carousel-v'>
                <Carousel
                    itemsToShow={1}
                    itemsToScroll={1}
                    itemPadding={[0, 5, 0, 5]}
                    enableSwipe={false}
                    disableArrowsOnEnd={false}
                    pagination={false}
                    showArrows={true}
                    renderArrow={({ type, onClick }) => {
                        const pointer = type === consts.PREV ? <SlArrowLeft className='arrows' /> :
                            <SlArrowRight className='arrows' />
                        return <button onClick={onClick} className='arrows'>{pointer}</button>
                    }}
                >
                    {
                        items.map((item, index) => <Item key={index} item={item} />)
                    }
                </Carousel>

            </div>
        </Box>

    )


}

function Item(props) {
    return (
        <Box>
            <img src={props.item.image} width={'100%'} alt="Item"/>
        </Box>
    )
}

