import * as React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    typography: {
        gtitle: {
            fontSize: '32pt',
            fontWeight: 600,
            color: 'white',
            lineHeight: '32pt',
            '@media (max-width: 992px)': {
                fontSize: '26pt',
            },
        },
        wtitle: {
          fontSize: '32pt',
          fontWeight: 600,
          color: 'white',
          lineHeight: '32pt',
          '@media (max-width: 992px)': {
              fontSize: '26pt',
          },
      },
        title: {
          fontSize: '26pt',
          fontWeight: 600,
          color: '#00652b',
          lineHeight: '32pt',
          '@media (max-width: 992px)': {
              fontSize: '26pt',
          },
      }
    },

});

export function Title({title, color, variant}) {

  const { t } = useTranslation();

  return (
    <>
        <Typography theme={theme} variant={variant} className='title' paragraph={true}
          sx={{
              fontWeight: '600',
              color: {color}
          }}
        >
          {t(title)}
        </Typography>
    </>
  )
}

export default Title