import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { Title } from '../../Title';


const theme = createTheme({
  typography: {
    gtitle: {
      fontSize: '32pt',
      fontWeight: 600,
      color: '#00652b',
      lineHeight: '32pt',
      '@media (max-width: 992px)': {
        fontSize: '32pt',
      },
    },
    textgb: {
      fontSize: '16pt',
      fontWeight: 600,
      color: '#00652b'
    },
    text: {
      fontSize: '16pt',
      color: 'black'
    }
  },

});

export default function CardNews ({ idCard, image, title, date, time, place, body }) {
  
  const { t } = useTranslation();

  const [link, setLink] = useState();

  useEffect(() => {
    setLink("?id=" + idCard)
  }, [idCard]);


  return (



    <Grid item sm={12} md={6} lg={3} sx={{ padding: '10px' }} >
       <Link to={link}>
      <Card sx={{ border: '1px solid #00652b'}} className='cards'>
        <CardActionArea>

          {image !== null ?
          <CardMedia
            component="img"
	    sx={{maxHeight: "400px"}}
            image={image}
          />
	    :
          <CardMedia
            component="img"
	    sx={{maxHeight: "400px"}}
            image="NIG-logo.png"
          />
	    }
          


          <CardContent>
            <Box className='cards-text-gt' >
             <Title title={title} variant="h5"></Title>

              <Box  />

              {/*<Typography variant="text" theme={theme}>

                {t(body)}

              </Typography>*/}
            </Box>


          </CardContent>
          
{/* 
          <ColorButton text="open" link={link} color="green"/>
*/}

        </CardActionArea>

      </Card>
      </Link>
    </Grid>



  )
}