import * as React from 'react';
import Box from '@mui/material/Box';
import { Title } from '../../Title';
import { TextBlack } from '../../TextBlack';
import { TextGreen } from '../../TextGreen';

export default function TextBox() {


  return (
    <Box id="initiative" className='maintextbox' sx={{
      marginTop: 2,
    }}>

      <Title title="what_is_nig" variant="title"></Title>

      <TextBlack text='initiative_nig'></TextBlack>

      <TextGreen text='vision_for_st'></TextGreen>

    </Box>


  );
}

