import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const theme = createTheme({
    typography: {
        gtitle: {
            fontSize: '42pt',
            fontWeight: 600,
            color: '#00652b',
            lineHeight: '42pt',
            '@media (max-width: 992px)': {
                fontSize: '32pt',
            },
        },
        smtext: {
            fontSize: '12pt',
            color: 'black'
        },
        ctext: {
            fontSize: '16pt',
            color: 'black',
            textAlign: 'justify',

        }
    },

});

export default function List({ list }) {

    return (
        <Box sx={{ textAlign: 'justify' }}>
            <ul className='ul'>
                <li >
                    <Typography variant="ctext" theme={theme} >

                        {list}

                    </Typography>
                </li>
            </ul>
        </Box>
    )
}