import * as React from 'react';
import './Menubar.css';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const AButton = styled(Button)({
  borderRadius: 0,
  fontSize: '16pt',
  color: 'white',
  textTransform: 'none',
  paddingBottom: '0px',
  paddingLeft: '15px',
  '&:hover': {
    boxShadow: '0px 3px white',
  },
  '&:active': {
    boxShadow: '0px 3px white',
    borderRadius: 0,
  },
  '&:focus': {
    borderRadius: 0,
    boxShadow: '0px 3px white',
  },

});

export function MenuButton2({ callback }) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  
  const { t } = useTranslation();



  return (
    <>

      <AButton size="large"
        onClick={handleClick} href=''
      >

        {t('services')}

      </AButton>
      <Popover

        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',

        }}
        className='opacity'
      >
        
        <Typography className='popover'
          sx={{ borderTop: 3 }}
        >
          <Link to="/services" className='link' onClick={() => { handleClose(); callback(); }} >
            {t('services')}
          </Link>
        </Typography>

        <Typography className='popover'>
          <Link to="/public" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('municipalities')}
          </Link>
        </Typography>

        <Typography className='popover'
          sx={{ paddingLeft: '50px' }}>
          <HashLink to="/public#consultation" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('consultation')}
          </HashLink>
        </Typography>

        <Typography className='popover'
          sx={{ paddingLeft: '50px' }}>
          <HashLink to="/public#bestpractice" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('best_practice')}
          </HashLink>
        </Typography>

        <Typography className='popover'>
          <Link to="/private" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('private')}
          </Link>
        </Typography>

        <Typography className='popover'
          sx={{ paddingLeft: '50px' }}>
          <HashLink to="/private#criterias" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('criterias')}
          </HashLink>
        </Typography>

        <Typography className='popover'
          sx={{ paddingLeft: '50px' }}>
          <HashLink to="/private#badge" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('badge_award')}
          </HashLink>
        </Typography>

        <Typography className='popover'
          sx={{ paddingLeft: '50px' }}>
          <HashLink to="/private#elements" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('nig_elements_title')}
          </HashLink>
        </Typography>

        <Typography className='popover'
          sx={{ paddingLeft: '50px' }}>
          <HashLink to="/private#care" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('nig_pflege_title')}
          </HashLink>
        </Typography>

        <Typography className='popover'>
          <Link to="/tours" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('tours')}
          </Link>
        </Typography>


      </Popover>

    </>
  );

}

