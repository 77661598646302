import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const theme = createTheme({
    typography: {
        gtitle: {
            fontSize: '42pt',
            fontWeight: 600,
            color: '#00652b',
            lineHeight: '42pt',
            '@media (max-width: 992px)': {
                fontSize: '32pt',
            },
        },
        smtext: {
            fontSize: '10pt',
            color: 'black',
            marginBottom: '20px',
        },
        ctext: {
            fontSize: '16pt',
            color: 'black',
        }
    },
});

export default function Image({ image, figure }) {

    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}>

           
            <Box sx={{
                marginTop: '20px',
                marginBottom: '20px',
                width: '100%',
                maxWidth: '800px',

            }}>
                <p>
                    <img src={image} alt={figure} width="100%" />
                </p>

                {figure !== undefined ?
                    <Typography variant="ctext" theme={theme.smtext}>
                        {figure}
                    </Typography> : ''}

            </Box>
            </Box>
        </>
    )
}