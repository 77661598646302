import * as React from 'react';
import './Menubar.css';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const AButton = styled(Button)({
  borderRadius: 0,
  fontSize: '16pt',
  color: 'white',
  textTransform: 'none',
  paddingBottom: '0px',
  paddingLeft: '15px',
  '&:hover': {
    boxShadow: '0px 3px white',
  },
  '&:active': {
    boxShadow: '0px 3px white',
    borderRadius: 0,

  },
  '&:focus': {
    borderRadius: 0,
    boxShadow: '0px 3px white',
  },

});
export function MenuButton3({ callback }) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  
  const { t } = useTranslation();
  
  return (
    <>

      <AButton size="large"
        onClick={handleClick}
      >
        {t('news&events')}
      </AButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='opacity'
      >

        <Typography className='popover'
          sx={{ borderTop: 3 }}
        >
          <Link to="/news" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('news')}
          </Link>
        </Typography>


        <Typography className='popover'
          sx={{ borderTop: 3 }}
        >
          <Link to="/events" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('events')}
          </Link>
        </Typography>

        <Typography className='popover'>
          <Link to="/gardentips" className='link' onClick={() => { handleClose(); callback(); }}>
            {t('gardentips')}
          </Link>
        </Typography>

      </Popover>



    </>
  );

}
