import * as React from 'react';
import Box from '@mui/material/Box';
import Image from '../../Links/IMG_1088.JPG';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";
import { Title } from '../../Title';

export default function Werwirsind() {

  const { t } = useTranslation();

  return (

    <div >

      <Grid container spacing={0} className='unsdien'>

        <Grid item xs={12} lg={6} id="team">

          <img src={Image} className='beratungen-im' alt="IMG_1088"
            width='100%'
          />

        </Grid>

        <Grid item xs={12} lg={6} sx={{ padding: 5 }} className='unser-textbox'>

          <Title title="who_are_we" variant="wtitle"></Title> 

          <Box sx={{ paddingBottom: 0 }} />

          {t('who_are_we_1')}
          <Box sx={{ paddingBottom: 2 }} />

          {t('who_are_we_2')}
          <Box sx={{ paddingBottom: 2 }} />

          <Box
	    sx={{
		fontSize: '18px'
	    }} >
              {t('who_are_we_3')}
		Marco Lovatel, Sara Nicli, Kathrin Plunger, Helga Salchegger, Jutta Pfitscher, Dietmar Battisti
	    </Box>

        </Grid>

      </Grid>


    </div>





  );
}