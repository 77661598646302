import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";


import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

/*import Select from 'react-select';*/
import Select from "@mui/material/Select";

import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 0,
    color: '#00652b',
    width: 30,
    height: 30,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px #00652b'
            : 'inset 0 0 0 1px #00652b, inset 0 -1px 0 #00652b',
    backgroundColor: theme.palette.mode === 'dark' ? '#00652b' : '#transparent',

    '.Mui-focusVisible &': {
        outline: '2px auto rgba(0,42.4,22,1)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#00652b' : 'transparent',
    },

}));

const BpCheckedIcon = styled (BpIcon)({
    backgroundColor: '#00652b',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 30,
        height: 30,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#00652b',
    },
});


const CssTextField = styled (TextField)({
    '& label.Mui-focused': {
        color: '#00652b',
        border: 1,
        borderRadius: 0,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00652b',
            borderRadius: 0,

        },
        '&.Mui-focused fieldset': {
            borderColor: '#00652b',
            borderRadius: 0,
        },
        '&:hover fieldset': {
            borderColor: '#00652b',
            borderRadius: 0,
        },
    },
});


  const defaultValues = {
    fullname: "",
    address: "",
    subject: "0",
    body: "",
    criteria: false,
    privacy: false,
};

  const schema = yup.object({
    fullname: yup.string().label("Full Name").required('missing').min(10),
    address: yup.string().required("missing").email(),
    subject: yup.string().label("subject").oneOf(["1", "2", "3"]),
    body: yup.string().label("Body").required("missing").min(10),
    criteria: yup.boolean().when('subject',
        {
            is: (subject) => subject === "1",
            then: () => yup.boolean().oneOf([true])
        }),
    privacy: yup.boolean().oneOf([true]),
  }).required();


export function Form() {

   
    const { resetField, watch, handleSubmit, formState: { errors }, control } = useForm({
        defaultValues,
        resolver: yupResolver(schema)
      }
    );
    
    const { t, } = useTranslation();

    const [krit, setKrit] = useState(false);
    const [open, setOpen] = React.useState(false);
   
    
    const onSubmitHandle = async data => {
        console.log(data)

        let s = "";
        switch (data.subject)
        {
            case "1":
                s = "Beratung Privat";
                break;
            case "2":
                s = "Beratung Gemeinden";
                break;
            case "3":
                s = "Anderes";
                break;
            default:
                break;
        }

        /* Open Snackbar */
        setOpen(true);

        await fetch('https://naturimgarten.laimburg.it/php/FormValidationAndSending.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'fullname': data.fullname, 'address': data.address, 'subject': s, 'body': data.body })
        })
            .then(response => response.text())
            .then(response => {
                console.log(response);
                setOpen(false);
        })
        

        resetField('fullname');
        resetField('address');
        resetField('subject');
        resetField('body');

        resetField('criteria');
        resetField('privacy');

        /*setValue('criteria', false);
        setValue('privacy', false);*/

        /*reset (data);*/

    };

    useEffect(() => {
        watch((value, { name, type }) => {
            console.log(value, name, type);
            if (value.subject === "1")
                setKrit(true)
            else
                setKrit(false)    
        });
    }, [watch]);

    const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
        </React.Fragment>
    );


    return (

        <Grid item xs={12} sm={12} md={8} style={{ padding: '20px' }}>

            <form onSubmit={handleSubmit(onSubmitHandle)} id="formular">

                <Grid container spacing={0} style={{ width: '100%' }}>

                    <Grid item xs={12}>

                        <Typography className="small-greentitle" fontWeight='bold' fontSize={20}>
                            {t('Gleich anfragen.')}
                        </Typography>

                    </Grid>

                    {/* Namen */}
                    <Grid item xs={12} sm={6} sx={{ padding: '5px' }}>
                        <FormControl sx={{ marginTop: 1, marginBottom: 0.7 , width: '100%' }} required>
                            <Controller
                                control={control}
                                name="fullname"
                                render={({ field }) => (
                                    <CssTextField
                                        {...field}
                                        fullWidth
                                        label={t('name')}
                                        margin="dense"
                                        error={!!errors.fullname}
                                    
                                    />
                                )}
                            />
                        </FormControl> 
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} sm={6} md={6} sx={{ padding: '5px' }}>
                    <FormControl sx={{ marginTop: 1, marginBottom: 0.7 , width: '100%' }} required>
                    <Controller
                        control={control}
                        name="address"
                        render={({ field }) => (
                            <CssTextField
                                {...field}
                                fullWidth
                                label={t('email')}
                                margin="dense"
                                error={!!errors.address}
                            />
                        )}
                    />
                    </FormControl>
                    </Grid>

                    {/* Subject */}
                    <Grid item xs={12} sx={{ padding: '5px' }}>
                    <FormControl
                        sx={{
                            marginTop: 1,
                            marginBottom: 0.7,
                            width: '100%',
                            '& label.Mui-focused' :{
                                borderColor: '#00652b',
                                color: '#00652b',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#00652b',
                                    borderRadius: 0,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#00652b',
                                    borderRadius: 0,
                                },
                                '&:hover fieldset': {
                                    borderColor: '#00652b',
                                    borderRadius: 0,
                                },
                            },
                            }}
                            required
                    >
                        <InputLabel
                            id="type-label"
                            className={errors.subject && 'errormessage'}
                            sx={{
                                '&:focused':{
                                    color: '#00652b'
                                },
                                '&':{
                                    color: '#00652b'
                                }
                            }}
                        >
                            {t('subject')}
                        </InputLabel>
                        
                        <Controller
                            control={control}
                            name="subject"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    margin="dense"
                                    type="select"
                                    labelId="type-label"
                                    label="subject"
                                    error={!!errors.subject}
                                    sx={{
                                        textAlign: 'left',
                                        boderColor: '#00652b',
                                        '& label.Mui-focused': {
                                            color: '#00652b',
                                            border: 1,
                                            borderRadius: 0,
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#00652b',
                                                borderRadius: 0,
                        
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#00652b',
                                                borderRadius: 0,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#00652b',
                                                borderRadius: 0,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="0"><em>-</em></MenuItem>
                                    <MenuItem value="1">{t('consultation_private')}</MenuItem>
                                    <MenuItem value="2">{t('consultation_public')}</MenuItem>
                                    <MenuItem value="3">{t('other')}</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>

                     </Grid>


                    {/* Body */}
                    <Grid item xs={12} sx={{ padding: '5px' }}>
                    <FormControl sx={{ marginTop: 1, marginBottom: 0.7, width: '100%' }}
                        required
                        className={errors.criteria && 'errormessage'}
                    >
                        <Controller
                            control={control}
                            name="body"
                            render={({ field }) => (
                                <CssTextField
                                {...field}
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                label={t('message')}
                                className='form'
                                error={!!errors.body}
                            />
                            )}
                        />
                    </FormControl>
                    </Grid>

                </Grid>


                {/* 3 Kriterien */}
                <Grid container justifyContent="space-between" paddingX={1}
                    sx={{
                        display: krit ? "" : "none" 
                    }}
                >

                    <FormControl component="fieldset">
                    <FormControlLabel
                        label={t('checkbox_1')}
                        className={errors.criteria && 'errormessage'}
                        control={
                            <Controller
                            control={control}
                            name="criteria"
                            render={({ field }) => 
                                <Checkbox
                                    {...field}
                                    className={errors.criteria && 'errormessage'}
                                    icon={<BpIcon />}
                                    checkedIcon={<BpCheckedIcon />}
                                    checked={field['value'] ?? false}
                                /> 
                            }
                        />
                        }
                    />
                    </FormControl>
                </Grid>
                
                {/* Privacy */}
                <Grid container justifyContent="space-between" paddingX={1}>

                    <FormControl component="fieldset">
                    <FormControlLabel
                        label={t('checkbox_2')}
                        className={errors.privacy && 'errormessage'}
                        control={
                            <Controller
                            control={control}
                            name="privacy"
                            render={({ field }) => 
                                <Checkbox
                                    {...field}
                                    className={errors.privacy && 'errormessage'}
                                    icon={<BpIcon />}
                                    checkedIcon={<BpCheckedIcon />}
                                    checked={field['value'] ?? false}
                                /> 
                            }
                        />
                        }
                    />
                    </FormControl>

                    <button type="submit" name={t('Absenden')} className="formButton">
                        {t('send_email')}
                    </button>

                </Grid> 


            </form>

            {/* 
                <Button color="primary" onClick={resetCheckbox}>RESET</Button>
            */}

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleCloseSnackBar}
                message="Sending ..."
                action={action}
            />

        </Grid >


    );
}
