import * as React from 'react';
import Image1 from '../../../Links/Entsiegelung_Deutschland.JPG';
import Image2 from '../../../Links/Holzdeck mit Fugenfarn.jpg';
import Image3 from '../../../Links/Strassenbegleitgruen Fulpmes.jpg';
import Grid from '@mui/material/Grid';


export default function PicturesGem() {

  return (

    <div>

      <Grid container spacing={0}>

        <Grid item xs={12} md={12} lg={4} className='photogallery' >

          <img src={Image1} className='gallery' alt="gallery"/>

        </Grid>

        <Grid item xs={12} md={12} lg={4} className='photogallery' >

          <img src={Image2} className='gallery' alt="gallery" />

        </Grid>

        <Grid item xs={12} md={12} lg={4} className='photogallery'>

          <img src={Image3} className='gallery' alt="gallery" />

        </Grid>

      </Grid>
    </div>

  );
}
