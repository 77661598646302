import * as React from 'react';
import Box from '@mui/material/Box';
import { Title } from '../../Title';
import { TextBlack } from '../../TextBlack';
import { TextGreen } from '../../TextGreen';


export default function Wastunwir() {

  return (
    <Box className='maintextbox'>

      <Title title="what_we_do" variant="title"></Title>

      <TextBlack text='what_we_do_1'></TextBlack>

      <TextGreen text='what_we_do_2'></TextGreen>

      <TextBlack text='what_we_do_3'></TextBlack>

      <TextBlack text='what_we_do_4'></TextBlack>

    </Box>


  );
}