import React from 'react';
import { Box } from '@mui/material'

import Image1 from '../../../Images/Home/Centaurea scabiosa mit Bienen und Hummel.jpg'
import Image2 from '../../../Images/Home/Streuobstwiese.jpg'
import Image3 from '../../../Images/Home/mushroom-gd610f868b_1920.jpg'
import Image4 from '../../../Images/Home/IMG_0363.JPG';
import Image5 from '../../../Images/Home/Winter Wald.jpg'
import Image6 from '../../../Images/Home/shutterstock_377458408.jpg';
import Image7 from '../../../Images/Home/Schwimmteich Privat Kiem.jpg'
import Image8 from '../../../Images/Home/Sedum Trockenmauer Jageregger Montan April 2013.JPG'

import Image9 from '../../../Images/Home/Rottensteiner Fotograf29.jpg';
import Image10 from '../../../Images/Home/Rottensteiner Fotograf-38.jpg';
import Image11 from '../../../Images/Home/IMG_0851.JPG';

import Image12 from '../../../Images/Home/bee-g498c283fe_1280.jpg';
import Image13 from '../../../Images/Home/bee-on-a-blossom-in-the-garden-gb3ae1ae3b_1280.jpg';
import Image14 from '../../../Images/Home/elder-gf06d1005a_1280.jpg';
import Image15 from '../../../Images/Home/garden-gb05829ff6_1280.jpg';


import Carousel, { consts } from 'react-elastic-carousel';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

var items = [
    {
        image: Image1,
    },
    {
        image: Image2,
    },
    {
        image: Image3,
    },
    {
        image: Image4,
    },
    {
        image: Image5,
    },
    {
        image: Image6,
    },
    {
        image: Image7,
    },
    {
        image: Image8,
    },
    {
        image: Image9,
    },
    {
        image: Image10,
    },
    {
        image: Image11,
    },
    {
        image: Image12,
    },
    {
        image: Image13,
    },
    {
        image: Image14,
    },
    {
        image: Image15,
    }
    
]




export default function Photogallery() {




    return (
        <div>
            <div className='carousel-g'>
                <Carousel
                    itemsToShow={3}
                    itemsToScroll={1}
                    itemPadding={[0, 5, 0, 5]}
                    enableSwipe={true}
                    disableArrowsOnEnd={false}
                    pagination={false}
                    renderArrow={({ type, onClick }) => {
                        const pointer = type === consts.PREV ? <SlArrowLeft className='arrows' /> :
                            <SlArrowRight className='arrows' />
                        return <button onClick={onClick} className='arrows'>{pointer}</button>
                    }}
                >
                    {
                        items.map((item, index) => <Item key={index} item={item} />)
                    }
                </Carousel>
            </div>

            <div className='carousel-v'>
                <Carousel
                    itemsToShow={1}
                    itemsToScroll={1}
                    itemPadding={[0, 5, 0, 5]}
                    enableSwipe={false}
                    disableArrowsOnEnd={false}
                    pagination={false}
                    showArrows={true}
                    renderArrow={({ type, onClick }) => {
                        const pointer = type === consts.PREV ? <SlArrowLeft className='arrows' /> :
                            <SlArrowRight className='arrows' />
                        return <button onClick={onClick} className='arrows'>{pointer}</button>
                    }}
                >
                    {
                        items.map((item, index) => <Item key={index} item={item} />)
                    }
                </Carousel>

            </div>
        </div>

    )


}

function Item(props) {
    return (
        <Box>
            <img src={props.item.image} width={'100%'} alt="Item"/>
        </Box>
    )
}

