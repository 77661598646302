import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './Menubar.css';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Paper } from '@mui/material';


const StyledAutocomplete = styled(Autocomplete)({

  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
    color: "white",
  },
  "&.Mui-focused .MuiInputLabel-outlined": {
    color: "white"
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "white",

    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      // Default left padding is 6px
      paddingLeft: '26pt'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: '25px'
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    }
  }
});


export default function SearchAutocomplete() {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const autolist = [
    { title: t('initiative'), goto: "/", hash: "initiative" },
    { title: t('team'), goto: "/", hash: "team" },

    { title: t('services'), goto: "/services" },
    { title: t('public_consultation'), goto: "/public", hash: "offer_gemeinde" },
    { title: t('green_areas'), goto: "/public", hash: "offer" },
    { title: t('public_areas'), goto: "/public", hash: "offer" },
    { title: t('consultation'), goto: "/public", hash: "consulatation" },
    { title: t('best_practice'), goto: "/public", hash: "bestpractice" },
    { title: t('private'), goto: "/private" },
    { title: t('private_garden'), goto: "/private", hash: "private_garden" },
    { title: t('gardener_him'), goto: "/private", hash: "private_garden" },
    { title: t('gardener_her'), goto: "/private", hash: "private_garden" },
    { title: t('garden'), goto: "/private", hash: "private_garden" },

    { title: t('kerncriterias'), goto: "/private", hash: "criterias" },
    { title: t('nig_elements'), goto: "/private", hash: "elements" },
    { title: t('care'), goto: "/private", hash: "care" },
    { title: t('use'), goto: "/private", hash: "care" },
    { title: t('rationing'), goto: "/private", hash: "badge" },

    { title: t('tours'), goto: "/tours" },
    { title: t('events'), goto: "/events" },
    { title: t('tips'), goto: "/gardentips" },
    { title: t('tips_private'), goto: "/downloads?id=1" },
    { title: t('tips_public'), goto: "/downloads?id=2" },
    { title: t('garden_knowledge'), goto: "/downloads?id=1" },
    { title: t('green_knowledge'), goto: "/downloads?id=1" },

    { title: t('brochure'), goto: "/downloads" },
    { title: t('infosheet'), goto: "/downloads" },

    { title: t('contact'), goto: "/", hash: "laimburg" },
    { title: t('formular'), goto: "/", hash: "formular" },
    { title: t('email'), goto: "/", hash: "laimburg" },
  ];

  /*const autolist_it = [
    { title: "Initiative", goto: "/", hash: "initiative" },
    { title: "Team", goto: "/", hash: "team" },

    { title: "Servizi", goto: "/services" },
    { title: "Consultazione comuni", goto: "/public", hash: "offer_gemeinde" },
    { title: "Zone verdi", goto: "/public", hash: "offer" },
    { title: "Zone verdi pubbliche", goto: "/public", hash: "offer" },
    { title: "Consultazione", goto: "/public", hash: "consulatation" },
    { title: "Best Practice", goto: "/public", hash: "bestpractice" },
    { title: "Privati", goto: "/private" },
    { title: "Giardini privati", goto: "/private", hash: "private_garden" },
    { title: "Giardineri privati", goto: "/private", hash: "private_garden" },
    { title: "Giardiniere private", goto: "/private", hash: "private_garden" },
    { title: "Giardini", goto: "/private", hash: "private_garden" },


    { title: "Criteri fondamentali", goto: "/private", hash: "criterias" },
    { title: "Elementi naturali", goto: "/private", hash: "elements" },
    { title: "Cura", goto: "/private", hash: "care" },
    { title: "Nutzung", goto: "/private", hash: "care" },
    { title: "Bewirtschaftung", goto: "/private", hash: "badge" },

    { title: "Visite guidate", goto: "/tours" },
    { title: "Eventi", goto: "/events" },
    { title: "Gartentipps", goto: "/gardentips" },
    { title: "Fachwissen Private", goto: "/downloads?id=1" },
    { title: "Fachwissen Gemeinden", goto: "/downloads?id=2" },
    { title: "Gartenwissen", goto: "/downloads?id=1" },
    { title: "Grünraumwissen", goto: "/downloads?id=1" },

    { title: "Broschüren", goto: "/downloads" },
    { title: "Infoblätter", goto: "/downloads" },

    { title: "Contatto", goto: "/", hash: "laimburg" },
    { title: t('formular'), goto: "/", hash: "formular" },
    { title: t('email'), goto: "/", hash: "laimburg" },
  ];*/

  const onTagsChange = (event, value) => {

    if (value != null) {
      if (value.hash != null)
        navigate(value.goto + '#' + value.hash, { replace: true });
      else
        navigate(value.goto, { replace: true });
    }
  }

  const CustomPaper = (props) => {
    return <Paper {...props}
      sx={{
        opacity: '0.9',
        backgroundColor: '#00652b',
        color: 'white'
      }}
    />;
  };

  /*

  InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
  */
  return (

    <>
      <StyledAutocomplete
        id="combo-box-search"
        options={autolist}
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        style={{
          marginRight: 5,
          align: 'right',
        }}
        PaperComponent={CustomPaper}
        onChange={onTagsChange}
        renderInput={(params) => {
          return (
            <TextField
            
              {...params}
              sx={{
                display: 'block',
                color: 'white',
                '& .MuiFormLabel-root': {
                  color: 'white',
                },
                '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
                  transform: 'translate(25px, 10px) scale(1)',
                }
                ,
                '& .MuiInputBase-root': {
                  padding: '1px'
                },

              }}
              label={t('search...')}
              variant="outlined"
              fullWidth
              
            />
          );
        }}
      />

    </>
  );
}