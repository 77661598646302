import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import { Title } from '../../../Title';

const GButton = styled(Button)({
  borderRadius: 0,
  color: 'white',
  backgroundColor: '#00652b',
  fontWeight: 'bold',
  fontSize: '16pt',
  textTransform: 'uppercase',
  minWidth: '210px',
  minHeight: '60px',
  '&:hover': {
    backgroundColor: '#00652b',
  },
});


export default function WennSieGem() {

  const { t } = useTranslation();

  return (


    <Box className='greybox' id='beratung' >

      <Title title="ask_for_advice_municipalities" variant="title"></Title>

      <Box sx={{ paddingBottom: 3 }} />
      <Box className='textgb'
      >
        {t('fill_out_formular')}
      <br />
      {t('or')}
      <br />
      {t('send_email_to')}

      <Link href="#" sx={{ color: '#00652b', textDecorationColor: '#00652b', }}>

      {t('nig_email')}

      </Link>
      </Box>
      <Box sx={{ paddingBottom: 2 }} />
      <GButton variant="contained" size="large" href='#formular' sx={{
        m: 1,

        '&.Mui-checked': {
          color: '#00652b',
        },
        minwidth: '300px',
        height: '60px',
      }}>
        {t('go_to_formular')}
      </GButton>

    </Box>

  );
}