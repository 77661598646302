import * as React from 'react';
import { useState, useEffect } from 'react';
import mainImage from '../../Links/IMG_0655.JPG';
import Box from '@mui/material/Box';
import NewsPrime from './NewsPrime';
import { useLocation } from 'react-router-dom';
import NewsItem from './NewsItem';
import { useTranslation } from "react-i18next";
import { useMatomo } from '@datapunt/matomo-tracker-react'

//import data_de from './events_de.json';
//import data_it from './events_it.json';


export default function News () {

  const location = useLocation();
  const { i18n } = useTranslation();

  const [extend, setExtend] = useState(false);

  const [list, setList] = useState([]);

  //const [cards, setCards] = useState(list);

  const { trackPageView } = useMatomo()

  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Veranstaltungen', // optional
      href: 'https://naturimgarten.laimburg.it', // optional
      customDimensions: [
        {
          id: 1,
          value: 'loggedIn',
        },
      ], // optional
    })
  }, [trackPageView])

  useEffect(() => {

    fetch(i18n.language==='de' ? 'News/news_de.json' : 'News/news_it.json')
      .then(response => response.json())
      .then(response => setList(response))
      .catch(error => {
          // handle the error
      });

  }, [i18n.language])

  const [id, setId] = useState(null);

  useEffect(() => {
    if (location.search !== undefined) {

      var param = new URLSearchParams(location.search).get('id')
     
      setId(param);
    }
  }, [location.search])


  useEffect(() => {
    let newarray = []

    list.map((item, index) => {

      if (extend)
        newarray.push(item);
      else
        if (index < 2)
          newarray.push(item)
      return 0;
    })

    //setCards(newarray);
  }, [extend, list])


  return (
    <Box>

      <Box className='mainimagebox' >
        <img className='mainimage' alt="IMG_0655.JPG"
          src={mainImage}
          width='100%'
        />
      </Box>
      <Box>
        {id === null ? <NewsPrime list={list} /> : ''}

        {id !== null ? <NewsItem item={list[id-1]} /> : ''}
      </Box>

      <Box id='linebox'>

      </Box>
      <Box id='t'></Box>

    </Box>
  );
}

