import * as React from 'react';
import Box from '@mui/material/Box';
import { Form } from './Form';
import { Laimburg } from './Laimburg';
import Grid from '@mui/material/Grid';

export default function Footer() {

  return (
    <div>

      <Box className="line" id='formular'></Box>

      <Grid container spacing={0} direction="row-reverse">
        
        <Form />

        <Laimburg />

      </Grid>
      {/*
      
      <div className='carousel-g'>
        <FooterLarge />
      </div>
      
      <div className='carousel-v'>
        <FooterSmall />

      </div>
      */}

    </div>
  );
}