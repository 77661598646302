import * as React from 'react';
import { useState, useEffect } from 'react';
import mainImage from '../../Links/Schaugarten.JPG';
import Box from '@mui/material/Box';
import CardsGartentipps from './CardsGartentipps';
import { useLocation } from 'react-router-dom';
import CardsGartentipp from './CardsGartentipp';
import { useTranslation } from "react-i18next";
import { useMatomo } from '@datapunt/matomo-tracker-react'

//import arrayOfCards_de from './tips_de.json'
//import arrayOfCards_it from './tips_it.json'

export default function Gartentipps() {
  
  const { i18n } = useTranslation();
  const location = useLocation();

  /* trackEvent */
  const { trackPageView } = useMatomo()

  const [id, setId] = useState(null);
  const [list, setList] = useState([]);

  useEffect (() => {
    if (location.search !== undefined)
    {
      /*console.log (new URLSearchParams(location.search).get('id'));*/
      var param = new URLSearchParams(location.search).get('id')
      /*console.log ("Location: "+ param);*/
      setId(param);
    }
  }, [location.search])


  useEffect(() => {

    fetch(i18n.language==='de' ? 'Gardentips/tips_de.json' : 'Gardentips/tips_it.json')
      .then(response => response.json())
      .then(response => setList(response.slice(0, 24)))
      .catch(error => {
          // handle the error
      });

  }, [i18n.language])

    // Track page view
    React.useEffect(() => {

      trackPageView({
        documentTitle: 'Gartentipps', // optional
        href: 'https://naturimgarten.laimburg.it'+location.pathname+location.search, // optional
        /*customDimensions: [
          {
            id: 1,
            value: 'loggedIn',
          },
        ],*/ // optional
      })
    }, [trackPageView, location])

  return (
    <Box>
      {id === null ?
      <Box className='mainimagebox' >
        <img className='mainimage'
          src={mainImage}
          alt="Main"
          width='100%'
        />
      </Box>
      : ''}
      
      <Box >

        
        {id === null ? <CardsGartentipps list={list}/> : '' }

        {id !== null ? <CardsGartentipp item={list[id-1]} /> : '' }

      </Box>

      <Box id='linebox'>

      </Box>

    </Box>
  );
}