import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

const theme = createTheme({
    typography: {
        gtitle: {
            fontSize: '42pt',
            fontWeight: 600,
            color: '#00652b',
            lineHeight: '42pt',
            '@media (max-width: 992px)': {
                fontSize: '32pt',
            },
        },
        smtext: {
            fontSize: '12pt',
            color: 'black'
        },
        ctext: {
            fontSize: '16pt',
            color: 'black',
            textAlign: 'justify',
            fontWeight: '600'
        }
    },

});

export default function Bold({ bold }) {

    return (
        <Typography variant="ctext" theme={theme}>
            <p>
                {bold}
            </p>
        </Typography>
    )
}