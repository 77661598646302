import * as React from 'react';
import { useLocation } from 'react-router-dom';
import mainImage from '../../Links/shutterstock_1636692952 (1).jpg';
import Box from '@mui/material/Box';
import UnsAngGem from './UnsAngGem';
import WennSieGem from './public/WennSieGem';
import WieIstDieGem from './public/WieIstDieGem';
import BestPractBei from './public/BestPractBei';
import PicturesGem from './public/PicturesGem';
import { useMatomo } from '@datapunt/matomo-tracker-react'

export default function Gemeinden() {

  let location = useLocation();

  const { trackPageView } = useMatomo()

  React.useEffect(() => {
    let lo = location.hash.substring(1);
    if (lo)
    {
      let el = document.getElementById(lo);
      if (el != null)
        el.scrollIntoView()
    }
  }, [location]);

  // Track page view
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Dienstleistungen Gemeinden', // optional
      href: 'https://naturimgarten.laimburg.it', // optional
      customDimensions: [
        {
          id: 1,
          value: 'loggedIn',
        },
      ], // optional
    })
  }, [trackPageView])

  return (
    <>
      <Box>

        <Box className='mainimagebox' >
          <img className='mainimage'
            src={mainImage}
            alt="Main"
            width='100%'
          />
        </Box>

        <UnsAngGem />

        <WieIstDieGem />

        <Box>
          <PicturesGem />
        </Box>

        <Box id="beratung">
          <WennSieGem />

        </Box>

        <BestPractBei />

      </Box>
    </>
  );
}