import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import CardG from './CardG';
import { useState, useEffect } from 'react';

export default function CardsGartentipps({ list }) {

  const [cards, setCards] = useState(list);

  const [extend, setExtend] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    let newarray = []

    list.map((item, index) => {

      //item.visible = !item.visible;
      if (extend)
        newarray.push(item);
      else
        if (index < 4)
          newarray.push(item)
      //item.visible = !item.visible;
      return 0;
    })

    setCards(newarray);
    //setExtend(!extend);
  }, [extend, list])

  return (

    <>
        <Grid container spacing={1} className='cardbox'  >

          {cards.map((row, index) => {
            return (
                <Grid key={index} item xs={12} sm={6} lg={3} sx={{ padding: '10px' }} >
                  {row.visible ? <CardG key={index} idCard={index+1} image={row.image} title={row.title} gbody={row.gbody} body={row.body} /> : ''}
                </Grid>
              )
          })}

        </Grid>

      <Box id='t'></Box>

      {cards.length > 3 ?
          <>
            <Typography id='btn' >
              {t('load_more_tips')}
            </Typography>

            <br />
            <IconButton onClick={() => { readmore(); setExtend(!extend); }}>
              <SlArrowDown id='btndown' className='arrows' />
              <SlArrowUp id='btnup' className='arrows' />
            </IconButton>
            </>
           : ""
      }

    </>
  );
}
function readmore() {
  var t = document.getElementById('t');
  var btn = document.getElementById('btn');
  var btndown = document.getElementById('btndown');
  var btnup = document.getElementById('btnup');

  if (t.style.display === 'none') {
    btn.style.display = 'InLine';
    t.style.display = 'InLine';
    btndown.style.display = 'InLine';
    btnup.style.display = 'none';
  }
  else {
    btn.style.display = 'none';
    t.style.display = 'none';
    btndown.style.display = 'none';
    btnup.style.display = 'Inline';
  }

}

