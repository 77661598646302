import * as React from 'react';
import Box from '@mui/material/Box';
import Image2 from '../../Links/Entsiegelung_Laimburg Dach Frühling.JPG'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { Title } from '../../Title';


const WButton = styled(Button)({
  borderRadius: 0,
  color: 'white',
  fontWeight: 'bold',
  fontSize: '18pt',
  textTransform: 'uppercase',
  border: 'white solid 1px',
  minWidth: '200px',
  Height: '60px',
  '&:hover': {
    border: 'white solid 1px',
  },
});

export default function UnserAngDien() {

  const { t } = useTranslation();

  const click = () => {
    window.scrollTo(0, 0)
  }

  return (

    <>

      <div className='unsdien'>

        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={6} className='beratungen'>

            <img src={Image2} className='beratungen-im' width={'100%'} alt="Entsiegelung" />

          </Grid>

          <Grid item xs={12} md={12} lg={6} className='unser-textbox'>


            <Title title="offer_municipality" variant="wtitle"></Title>

            <Box sx={{ paddingBottom: 2 }} />

            {t('offer_municipality_1')}

            <Box sx={{ paddingBottom: 2 }} />
            <Box>
              <Stack spacing={2} direction="row">
                <Link to="/public">
                  <WButton variant="outlined" onClick={click}>
                    {t('read_more')}
                  </WButton>
                </Link>
              </Stack>
            </Box>

          </Grid>

        </Grid>


      </div>






    </>





  );
}
